<template>
  <div>
    <bread-cum v-bind:breadcrumbItems="['จัดการสมาชิก', 'จัดอันกับ']" />
    <loading v-show="isLoading" />
    <div class="container-fluid" v-show="!isLoading">
      <div class="row">
        <div
          class="col-12 col-lg-6 col-xl-4 mb-3"
          v-for="(table, ind) in tables"
          :key="ind"
        >
          <h6>{{ table.name }}</h6>
          <table
            class="table table-bordered table-hover table-dark text-center w-100"
          >
            <thead>
              <tr>
                <th>อันดับ#</th>
                <th>ชื่อ-นามสกุล</th>
                <th class="text-right">จำนวนเงิน</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(user, ind) in table.items"
                :key="table.name + user.id"
              >
                <td>#{{ ind + 1 }}</td>
                <td class="text-left">
                  <router-link
                    :to="{
                      name: 'MemberDetail',
                      params: { userId: user.id },
                    }"
                  >
                    {{ user.username }}
                  </router-link>
                </td>
                <td class="text-right">{{ util.formatMoney(user.amount) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.table {
  white-space: normal;
}
</style>

<script>
import UserProvider from "@/resources/user_provider"
const userService = new UserProvider()

export default {
  data() {
    return {
      isLoading: false,
      topDailyDeposit: [],
      topDailyWithdraw: [],
      topDailyBonus: [],
      topMonthlyDeposit: [],
      topMonthlyWithdraw: [],
      topMonthlyBonus: [],
      topCreditBalance: [],
      topEarnAF: [],
    }
  },
  computed: {
    tables() {
      return [
        {
          name: "ฝากสูงสุดประจำวัน",
          items: this.topDailyDeposit,
        },
        {
          name: "ถอนสูงสุดประจำวัน",
          items: this.topDailyWithdraw,
        },
        {
          name: "รับโบนัสสูงสุดประจำวัน",
          items: this.topDailyBonus,
        },
        {
          name: "ฝากสูงสุดประจำเดือน",
          items: this.topMonthlyDeposit,
        },
        {
          name: "ถอนสูงสุดประจำเดือน",
          items: this.topMonthlyWithdraw,
        },
        {
          name: "รับโบนัสสูงสุดประจำเดือน",
          items: this.topMonthlyBonus,
        },
        {
          name: "ยอดเงินคงเหลือในกระเป๋าสูงสุด",
          items: this.topCreditBalance,
        },
        {
          name: "รับแนะนำเพื่อนสูงสุดประจำเดือน",
          items: this.topEarnAF,
        },
      ]
    },
  },
  methods: {
    async getRankingData() {
      this.isLoading = true
      const res = await userService.getRankingData()
      this.isLoading = false
      if (res.error) {
        this.swal.swalError(res.error.message)
        return
      }
      this.topDailyDeposit = res.result.topDailyDeposit
      this.topDailyWithdraw = res.result.topDailyWithdraw
      this.topDailyBonus = res.result.topDailyBonus
      this.topMonthlyDeposit = res.result.topMonthlyDeposit
      this.topMonthlyWithdraw = res.result.topMonthlyWithdraw
      this.topMonthlyBonus = res.result.topMonthlyBonus
      this.topCreditBalance = res.result.topCreditBalance
      this.topEarnAF = res.result.topEarnAF
    },
  },
  mounted() {
    this.getRankingData()
  },
}
</script>